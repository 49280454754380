import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Input, message } from 'antd';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import ReactDOM from 'react-dom';
import ProgressButton from 'react-progress-button';
import useWindowSize from 'react-use/lib/useWindowSize';
import { configMode } from 'src/_shared/api/';
import {
	createContact,
	updateContact,
} from 'src/_shared/api/graphql/custom/contacts/';
import { queryReferralsByJobIdIndex } from 'src/_shared/api/graphql/custom/referrals/';
import { GetUserByCognitoId } from 'src/_shared/api/graphql/custom/users/';
import FormElements from 'src/form-builder/FormElements.jsx';
import {
	filterPhoneNumber,
	lambda,
	ml,
	sanitize,
	uploadToS3Multipart,
} from '../../services/utils';
import ReferralFormAddContactFields from './ReferralFormAddContactFieldsComponent.jsx';

// Native input that intercepts onChange and gets file because otherwise we can't use antD validation with
// clickable label htmlFor the input because label needs to be in fieldDecorator, which causes validation
// not to run on the input

// This component is used to make referrals
function ResumeInput({ onChange, value, isResumeRequired, ...rest }) {
	return (
		<>
			{!value && (
				<div className="click-to-upload">
					<label htmlFor="resumeFile">
						<span className="link">{'Click here '}</span>
						<input
							type="file"
							accept=".doc,.docx,application/msword,.pdf,application/pdf"
							onChange={onChange}
							{...rest}
						/>
						to attach a resume
						<span className="label-optional">
							{isResumeRequired ? ' (required)' : ' (optional)'}
						</span>
					</label>
				</div>
			)}
			{value && (
				<div className="upload-file-text">
					<p>
						{value.name.length > 50
							? value.name.slice(0, 50) + '...'
							: value.name}
						&nbsp;
						<i
							className="icon-bin text-danger cursor-p"
							onClick={() => {
								onChange({ target: { files: [] } });
							}}
						/>
					</p>
				</div>
			)}
		</>
	);
}

const inputs = {};
function ReferralForm(props) {
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
	const [referralType, setReferralType] = useState('email');
	const [buttonState, setButtonState] = useState('');
	const [selectedContact, setSelectedContact] = useState(props.contact);
	const [showConfetti, setShowConfetti] = useState(false);
	const [errors, setErrors] = useState('');
	const { width, height } = useWindowSize();

	const {
		allMultiLingualData,
		campaignId,
		client,
		companyData,
		contacts,
		currentUser,
		form,
		handleCancel,
		handleNotificationCompleted,
		handleViewContact,
		job,
		notification,
		onCreateReferral,
		referralQuestions,
		resumeAttachData,
		setCurrentUser,
		setUpdatedContacts,
	} = props;

	const pointsSettings = get(companyData, 'pointsSettings');
	let referralSentPoints = null;
	if (pointsSettings !== null && pointsSettings !== undefined) {
		const pointsSettingsData = JSON.parse(pointsSettings);
		if (pointsSettingsData.enabled) {
			referralSentPoints = get(pointsSettingsData, 'referralSent');
		}
	}

	// Find the resume requirements for referral form form builder (non candidate)
	const formBuilderResumeCharacteristicsForAdmins =
		resumeAttachData &&
		resumeAttachData.find(
			(item) =>
				item.isCandidate === false &&
				item.isInterested !== true &&
				item.isGeneral !== true &&
				!item.subCompanyId &&
				item.sortOrder === 0
		);

	const isAllowResumeUpload = formBuilderResumeCharacteristicsForAdmins
		? formBuilderResumeCharacteristicsForAdmins.questions[
				'Ask for resume attachment'
			]
		: false;
	const isResumeRequired = formBuilderResumeCharacteristicsForAdmins
		? formBuilderResumeCharacteristicsForAdmins.questions['Resume required']
		: false;
	const { getFieldDecorator } = form;
	const { TextArea } = Input;
	const FormItem = Form.Item;

	const fetchUser = async (employee) => {
		const { data } = await client.query({
			query: GetUserByCognitoId,
			variables: { cognitoId: employee.cognitoId },
		});
		const currentUser = {
			...data.getUserByCognitoId,
		};
		setCurrentUser(currentUser);
	};

	// Handler for email/phone number referral method toggler
	const changeReferralType = (referralType) => {
		setReferralType(referralType);
	};

	const handleSelectContact = async (contact) => {
		// Make sure job isn't undefined
		if (!job && !job.id) {
			return;
		}

		// Asyncronously validate to see if this contact has already been referred,
		// and show error if it has, else set to state and change mode
		try {
			const alreadyReferred = await client
				.query({
					query: gql(queryReferralsByJobIdIndex),
					variables: { jobId: job.id },
					fetchPolicy: 'network-only',
				})
				.then((response) =>
					response.data.queryReferralsByJobIdIndex.items.some(
						(referral) =>
							(referral.contact &&
								referral.contact.emailAddress &&
								referral.contact.emailAddress === contact.emailAddress) ||
							(referral.contact &&
								referral.contact.phoneNumber &&
								filterPhoneNumber(referral.contact.phoneNumber) ===
									contact.phoneNumber)
					)
				);

			if (alreadyReferred) {
				message.error('You Already Referred That Person', 5);
			} else {
				// Set selected contact, and change JSX mode to display selectedContact
				setSelectedContact(contact);
				// When user selects a contact, default back whatever referralType is available.
				if (contact.emailAddress) {
					setReferralType('email');
				} else if (contact.phoneNumber) {
					setReferralType('text');
				}
			}
		} catch (error) {
			console.log(
				'validator error for alreadyReferred in handleSelectContact',
				error
			);
		}
	};

	const handleClearSelected = () => {
		setSelectedContact(null);
		form.resetFields();
	};

	const createNewContact = async (contact) => {
		const newContact = await client.mutate({
			mutation: gql(createContact),
			variables: { input: contact },
		});

		if (setUpdatedContacts) {
			setUpdatedContacts(newContact.data.createContact);
		}

		return newContact.data.createContact;
	};

	const updateExistingContact = async (contact) => {
		await client.mutate({
			mutation: gql(updateContact),
			variables: { input: contact },
		});
	};

	// START FORM BUILDER----------------------
	const data_items = referralQuestions;
	const items = data_items.map((item, index) => {
		if (!item) return null;
		switch (item.element) {
			case 'TextInput':
			case 'NumberInput':
			case 'TextArea':
			case 'Dropdown':
			case 'DatePicker':
			case 'RadioButtons':
			case 'Rating':
			case 'Tags':
			case 'Range': {
				return getInputElement(item, index);
			}

			default: {
				return getSimpleElement(item, index);
			}
		}
	});

	function _getItemValue(item, ref) {
		let $item = {
			element: item.element,
			value: '',
			text: '',
		};
		switch (item.element) {
			case 'Rating': {
				$item.value = ref.inputField.current.state.rating;

				break;
			}

			case 'Tags': {
				$item.value = ref.inputField.current.state.value;

				break;
			}

			case 'DatePicker': {
				$item.value = ref.state.value;

				break;
			}

			case 'Camera': {
				$item.value = ref.state.img
					? ref.state.img.replace('data:image/png;base64,', '')
					: '';

				break;
			}

			default: {
				if (ref && ref.inputField) {
					$item = ReactDOM.findDOMNode(ref.inputField.current);
					if (typeof $item.value === 'string') {
						$item.value = $item.value.trim();
						if (item.element === 'Dropdown') {
							$item.text = $item.selectedOptions[0].innerText;
						}
					}
				}
			}
		}

		return $item;
	}

	function _collect(item) {
		const errors = [];
		const itemData = { name: item.field_name };
		const ref = inputs[item.field_name];
		if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
			const checked_options = [];
			for (const option of item.options) {
				const $option = ReactDOM.findDOMNode(
					ref.options[`child_ref_${option.key}`]
				);
				if ($option.checked) {
					checked_options.push(option.key);
				}
			}

			itemData.value = checked_options;
		} else {
			if (!ref) return null;
			itemData.value = _getItemValue(item, ref).value;

			itemData.question = item.label;
			itemData.text =
				_getItemValue(item, ref).text === undefined
					? ''
					: _getItemValue(item, ref).text;
			if (itemData.value === '' && itemData.text === '') {
				errors.push(`${item.label} is required.`);
			}
		}

		const object = {
			itemData,
			errors,
		};
		return object;
	}

	function _collectFormData(data) {
		const formData = [];
		let errors = '';
		for (const item of data) {
			const item_data =
				_collect(item) === null ? null : _collect(item).itemData;
			errors += _collect(item) === null ? '' : _collect(item).errors;
			if (item_data) {
				formData.push(item_data);
			}
		}

		const object = {
			formData,
			errors,
		};
		return object;
	}

	function getInputElement(item, index) {
		const Input = FormElements[item.element];
		return (
			<div key={index}>
				<Input
					ref={(c) => (inputs[item.field_name] = c)}
					key={`form_${item.id}`}
					mutable
					data={item}
					errors={errors}
					read_only={props.read_only}
				/>
			</div>
		);
	}

	function getSimpleElement(item, index) {
		const Element = FormElements[item.element];
		return <Element key={index} mutable data={item} />;
	}

	// ----END FORM BUILDER---------------------

	const onFileInputChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const inputErrors = [];
			const isDocOrDocsOrPdf =
				e.target.files[0].type === 'application/msword' ||
				e.target.files[0].type ===
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				e.target.files[0].type === 'application/pdf';
			if (!isDocOrDocsOrPdf) {
				inputErrors.push('You can only upload doc, docx, pdf files! ');
			}

			const isLt2M = e.target.files[0].size / 1024 / 1024 < 2;
			if (!isLt2M) {
				inputErrors.push('File size must smaller than 2MB!');
			}

			if (inputErrors.length > 0) {
				setErrors(inputErrors);
				return;
			}

			setErrors([]);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		// Check to ensure values are not undefined before proceeding
		if (!currentUser || !currentUser.id || !job || !job.id) {
			return;
		}

		const { errors, values } = await new Promise((resolve) => {
			form.validateFields((errors, values) => {
				resolve({ errors, values });
			});
		});
		try {
			const questionsData = _collectFormData(props.referralQuestions).formData;
			questionsData.map((item) => {
				const value = sanitize(item.value);
				item.value = value;
			});

			const isEmptySelect = questionsData.some(
				(item) =>
					item.name.includes('dropdown') &&
					item.text === 'Select' &&
					item.value === '0'
			);

			const quesErrors = _collectFormData(props.referralQuestions).errors;
			if (errors || quesErrors.length > 0 || isEmptySelect) {
				setButtonState('error');
				setErrors(quesErrors);
				setTimeout(() => {
					setButtonState('');
				}, 1500);
				return;
			}

			// If user already submitted, return if they click again. Prevents them from double clicking submit
			if (isSubmitDisabled) {
				return;
			}

			// If no errors
			setButtonState('loading');
			setIsSubmitDisabled(true);

			const d = new Date();
			const dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
			const isGeneralReferral = get(job, 'isGeneralReferral', false);
			if (selectedContact) {
				if (values.resumeFile) {
					try {
						const contactResume = {
							bucket: 'erin-documents',
							key: `resumes/${selectedContact.id}/${dformat}-
				  ${values.resumeFile.name}`,
							region: 'us-east-2',
						};

						await uploadToS3Multipart(
							values.resumeFile,
							contactResume.key,
							contactResume.bucket
						);
						await updateExistingContact({
							id: selectedContact.id,
							contactResume,
						});
					} catch (error) {
						console.error(error);
					}
				}

				const referral = {
					companyId: currentUser.companyId,
					contactId: selectedContact.id,
					referralType,
					userId: currentUser.id,
					jobId: job.id,
					status: 'referred',
					referralSource: isGeneralReferral ? 'general' : 'direct',
					referralDevice: 'web',
				};
				if (get(notification, 'type') === 'referralRequested')
					referral.referralSource = 'network';

				if (values.note) referral.note = values.note;
				if (values.message) referral.message = values.message;
				const createdReferral = await onCreateReferral(referral).then(
					(response) => response.data.createReferral
				);
				if (createdReferral && handleNotificationCompleted)
					handleNotificationCompleted();
				// -------------------------- START ENABLE PROSPECT CREATION---------------------------

				if (
					get(createdReferral, 'job.externalSource') === 'Greenhouse' &&
					get(createdReferral, 'contact.emailAddress') &&
					get(currentUser, 'company.enableProspectCreation')
				) {
					const prospect = {
						firstName: createdReferral.contact.firstName,
						lastName: createdReferral.contact.lastName,
						emailId: createdReferral.contact.emailAddress,
						externalJobId: createdReferral.job.externalJobId,
						companyId: currentUser.companyId,
						referralEmailId: createdReferral.user.emailAddress,
						custom_fields: [],
					};

					prospect.configMode = configMode === 'DEV' ? 'dev' : 'prod';
					await lambda({
						endpoint: 'GreeGreenhouseCreateProspectDevnhouseC',
						variables: prospect,
					});
				}

				// --------------------------END PROSPECT CREATION-----------------------------

				// Show success
				setButtonState('success');
				if (
					referralSentPoints &&
					referralSentPoints !== 0 &&
					referralSentPoints !== '0'
				) {
					message.success(`You earned ${referralSentPoints} points`, 5);
					setShowConfetti(true);

					fetchUser(currentUser);
				} else {
					message.success('Your request is submitted.', 5);
				}

				await new Promise((resolve) => {
					setTimeout(() => resolve(), 2000);
				});

				// Close modal
				handleCancel();

				// If fail, reset submit button and allow user to try again
				setButtonState('');
				setIsSubmitDisabled(false);
				// Submitting referral for new contact-------------------------
			} else {
				let isAlreadyReferredToJob = false;
				try {
					isAlreadyReferredToJob = await client
						.query({
							query: gql(queryReferralsByJobIdIndex),
							variables: { jobId: job.id },
							fetchPolicy: 'network-only',
						})
						.then((response) =>
							response.data.queryReferralsByJobIdIndex.items.some(
								(referral) =>
									(referral.contact &&
										referral.contact.emailAddress &&
										referral.contact.emailAddress === values.emailAddress) ||
									(referral.contact &&
										referral.contact.phoneNumber &&
										filterPhoneNumber(referral.contact.phoneNumber) ===
											values.phoneNumber)
							)
						);
				} catch (error) {
					console.log('validator error for alreadyReferredToJob', error);
				}

				if (isAlreadyReferredToJob) {
					message.error(
						'This person has already been referred for this job.',
						5
					);
					setButtonState('');
					setIsSubmitDisabled(false);
					return;
				}

				const contact = {
					firstName: sanitize(values.firstName),
					lastName: sanitize(values.lastName),
					userId: currentUser.id,
					companyId: currentUser.companyId,
				};
				if (values.phoneNumber) {
					contact.phoneNumber = sanitize(values.phoneNumber);
					contact.importMethod = 'mobile';
				}

				if (values.emailAddress) {
					contact.emailAddress = sanitize(values.emailAddress.toLowerCase());
					contact.importMethod = 'email';
				}

				if (
					companyData.disableSmartReferrals ||
					currentUser.company.disableSmartReferrals
				) {
					contact.disableSmartReferrals = true;
				}

				// Create a new contact
				const createdContact = await createNewContact(contact);

				try {
					if (values.resumeFile) {
						const contactResume = {
							bucket: 'erin-documents',
							key: `resumes/${createdContact.id}/${dformat}-${values.resumeFile.name}`,
							region: 'us-east-2`',
						};

						await uploadToS3Multipart(
							values.resumeFile,
							contactResume.key,
							contactResume.bucket
						);
						await updateExistingContact({
							id: createdContact.id,
							contactResume,
						});
					}
				} catch (error) {
					console.error(error);
				}

				const referral = {
					companyId: currentUser.companyId,
					contactId: createdContact.id,
					referralType,
					userId: currentUser.id,
					jobId: job.id,
					status: 'referred',
					referralSource: isGeneralReferral ? 'general' : 'direct',
					referralDevice: 'web',
					questionsData: JSON.stringify(questionsData),
				};
				if (campaignId) {
					referral.campaignId = campaignId;
				}

				if (values.note) {
					referral.note = sanitize(values.note);
				}

				if (values.message) {
					referral.message = sanitize(values.message);
				}

				// Then create a referral with newly created contact
				const createdReferral = await onCreateReferral(referral);
				if (createdReferral && handleNotificationCompleted) {
					handleNotificationCompleted();
				}

				// -------------------------START ENABLE PROSPECT CREATION NEW CONTACT----------------------------

				if (
					get(createdReferral, 'job.externalSource') === 'Greenhouse' &&
					get(createdReferral, 'contact.emailAddress') &&
					currentUser.company &&
					get(currentUser, 'company.enableProspectCreation')
				) {
					const prospect = {
						firstName: createdReferral.contact.firstName,
						lastName: createdReferral.contact.lastName,
						emailId: createdReferral.contact.emailAddress,
						externalJobId: createdReferral.job.externalJobId,
						companyId: currentUser.companyId,
						referralEmailId: createdReferral.user.emailAddress,
						custom_fields: [],
					};

					prospect.configMode = configMode == 'DEV' ? 'dev' : 'prod';
					await lambda({
						endpoint: 'GreenhouseCreateProspectDev',
						variables: prospect,
					});
				}
				// -------------------------END PROSPECT CREATION NEW CONTACT----------------------------

				// Show success
				setButtonState('success');
				if (
					referralSentPoints &&
					referralSentPoints !== 0 &&
					referralSentPoints !== '0'
				) {
					message.success(`You earned ${referralSentPoints} points`, 5);
					setShowConfetti(true);
					fetchUser(currentUser);
				} else {
					message.success('Your request is submitted.', 5);
				}

				await new Promise((resolve) => {
					setTimeout(() => resolve(), 2000);
				});

				handleCancel();

				setButtonState('');
				setIsSubmitDisabled(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Form onSubmit={handleSubmit}>
			{showConfetti && (
				<div
					style={{
						position: 'absolute',
						top: '5px',
						width: '10%',
						left: '45%',
					}}
				>
					<ConfettiExplosion
						style={{
							force: 1,
							duration: 4000,
							particleCount: 400,
							height,
							width,
							margin: 'auto',
						}}
					/>
				</div>
			)}
			<ReferralFormAddContactFields
				handleViewContact={handleViewContact}
				contact={props.contact}
				form={props.form}
				selectedContact={selectedContact}
				handleSelectContact={handleSelectContact}
				handleClearSelected={handleClearSelected}
				referralType={referralType}
				changeReferralType={changeReferralType}
				contacts={contacts}
				handleUpdateContacts={setUpdatedContacts}
				currentUser={currentUser}
				allMultiLingualData={allMultiLingualData}
				job={job}
			/>
			<div className="custom-form-group">
				<label className="custom-label">
					{ml(
						'Include a message to your contact',
						currentUser,
						allMultiLingualData
					)}
					<span> {ml('(optional)', currentUser, allMultiLingualData)}</span>
				</label>
				<FormItem>
					{getFieldDecorator(
						'note',
						{}
					)(
						<TextArea
							className="custom-input"
							placeholder={ml(
								'Personalize the message to your referral',
								currentUser,
								allMultiLingualData
							)}
							rows={4}
						/>
					)}
				</FormItem>
			</div>
			{!props.currentUser.company.hideMessageRecruiter && (
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Message the Recruiter', currentUser, allMultiLingualData)}
						<span> {ml('(optional)', currentUser, allMultiLingualData)}</span>
					</label>
					<FormItem>
						{getFieldDecorator(
							'message',
							{}
						)(
							<TextArea
								className="custom-input"
								placeholder={ml(
									'How do you know them, why are they a good fit, etc.',
									currentUser,
									allMultiLingualData
								)}
								rows={4}
							/>
						)}
					</FormItem>
				</div>
			)}
			<>{items}</>
			{/* If contact already has resume on query, don't allow user to change or delete it.  */}
			{selectedContact && selectedContact.contactResume ? (
				<div>
					{`Contact's current resume attached (${
						selectedContact.contactResume.key.split('-')[7]
					})`}
					&nbsp;
					<CheckCircleTwoTone twoToneColor="#52c41a" />
				</div>
			) : isAllowResumeUpload ? (
				<Form.Item>
					{getFieldDecorator('resumeFile', {
						getValueFromEvent: (event) => event.target.files[0],
						rules: [
							{ required: isResumeRequired, message: 'Required' },
							{
								validator(rule, value, callback) {
									// Can't use file.type because windowsOS doesn't recognize
									// file type if office not installed, or content type not set for docx files. Using file name instead
									if (!value) return callback();
									const fileNameAsArray = value.name.split('.');
									const fileExtension = fileNameAsArray.at(-1);
									if (
										value &&
										!['doc', 'docx', 'pdf'].includes(fileExtension)
									) {
										callback('You can only upload doc, docx, pdf files!');
									}

									callback();
								},
							},
						],
					})(
						<ResumeInput
							isResumeRequired={isResumeRequired}
							onChange={onFileInputChange}
						/>
					)}
				</Form.Item>
			) : null}
			<div className="modal-footer-btn">
				<ProgressButton controlled durationSuccess={3000} state={buttonState}>
					{ml('Submit Referral', currentUser, allMultiLingualData)}
				</ProgressButton>
			</div>
		</Form>
	);
}

export default Form.create()(ReferralForm);
