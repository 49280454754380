import { List } from 'antd';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { getReferralNew } from 'src/_shared/api/graphql/custom/referrals/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { ml } from 'src/_shared/services/utils';
import { ReferralCardJob as ReferralCard } from '../_shared/components/referral-card/ReferralCardContainer.jsx';
import { EditableReferralDetailsCard } from '../_shared/components/referral-detail/ReferralDetailCardComponent.jsx';

class DetailsView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			currencyRate: 1,
			currencySymbol: '$',
			referrals: get(props, 'referral.referrals'),
		};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (prevProps.referral !== this.props.referral) {
			this.setState({ referrals: get(this.props, 'referral.referrals') });
		}
	}

	async getReferralData(policy = 'network-only') {
		const { client } = this.props;
		try {
			const { data } = await client.query({
				query: gql(getReferralNew),
				variables: {
					id: get(this.props, 'referral.referralId'),
				},
				fetchPolicy: policy,
			});
			const result = get(data, 'getReferral');
			this.setState({
				referrals: result,
			});
		} catch (error) {
			console.log(error);
		}
	}

	deleteReferral = (referral) => {
		this.props.deleteReferral(referral);
		const referrals = get(this.state, 'referrals');
		const index = referrals.findIndex(
			(ref) => get(ref, 'id') == get(referral, 'id')
		);
		referrals.splice(index, 1);
		this.setState({ referrals });
	};

	goBack = () => {
		this.props.history.push('/referrals');
	};

	sortByAlph = (a, b) => {
		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	};

	render() {
		const { currencyRate, currencySymbol } = this.state;
		const {
			allMultiLingualData,
			currentUser,
			addGeneralReferralText,
			addJobHistoryText,
			cancelAddText,
			companyText,
			confirmNewPositionText,
			descriptionText,
			downloadResumeText,
			editProfileText,
			endDatesText,
			mobileText,
			myContactsText,
			organizationText,
			ownershipSettings,
			positionText,
			referredByText,
			referralCommentsText,
			referralReadyText,
			referralsText,
			referredOnText,
			resumeText,
			showJobHistoryText,
			startDateText,
			statusText,
			tieredBonusText,
		} = this.props;
		const { company, referrals } = this.state;
		return (
			<main>
				<span className="custom-back-btn" onClick={this.goBack}>
					<i className="icon-arrow-left" />
					{ml('Back', currentUser, allMultiLingualData)}
				</span>
				<div className="referrrals-profile">
					<EditableReferralDetailsCard
						key="referral"
						referralNote
						addGeneralReferralText={addGeneralReferralText}
						addJobHistoryText={addJobHistoryText}
						cancelAddText={cancelAddText}
						companyText={companyText}
						confirmNewPositionText={confirmNewPositionText}
						allMultiLingualData={allMultiLingualData}
						currentUser={currentUser}
						descriptionText={descriptionText}
						downloadResumeText={downloadResumeText}
						edit={this.props.edit ? this.props.edit : false}
						editProfileText={editProfileText}
						employee={this.props.employeeData}
						endDatesText={endDatesText}
						mobileText={mobileText}
						myContactsText={myContactsText}
						organizationText={organizationText}
						positionText={positionText}
						referral={this.props.referral}
						referralCommentsText={referralCommentsText}
						referralReadyText={referralReadyText}
						resumeText={resumeText}
						showJobHistoryText={showJobHistoryText}
						startDateText={startDateText}
						tieredBonusText={tieredBonusText}
						onUpdate={this.props.onUpdate}
						onUpdateContact={this.props.onUpdateContact}
						onUpdateReferral={this.props.onUpdateReferral}
					/>
				</div>
				<h4 className="referrrals-details-heading">
					{ml('Referrals', currentUser, allMultiLingualData)}
				</h4>
				{referrals ? (
					<List
						className="rd-wrap"
						grid={{ gutter: 14, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 3 }}
						dataSource={referrals}
						itemLayout="horizontal"
						renderItem={(referral) => (
							<List.Item>
								<ReferralCard
									key={get(referral, 'referralId', get(referral, 'id'))}
									currentCurrencyRate={currencyRate}
									currentCurrencySymbol={currencySymbol}
									allMultiLingualData={allMultiLingualData}
									currentUser={currentUser}
									deleteReferral={this.deleteReferral}
									employee={this.props.employeeData}
									ownershipSettings={ownershipSettings}
									referral={referral}
									referredByText={referredByText}
									referredOnText={referredOnText}
									statusText={statusText}
									onUpdateJob={this.props.onUpdateJob}
									onUpdateReferral={this.props.onUpdateReferral}
								/>
							</List.Item>
						)}
					/>
				) : (
					<Spinner company={company} />
				)}
			</main>
		);
	}
}

export default withApollo(DetailsView);
